<template>
    <div class="page-content">
        <page-breadcrumb v-if="item" title="Course Detail" class="mb-2" :items="breadcrumbItems" />
        <section class="mb-2">
        <b-card class="mt-1" v-if="item">
            <form-generator :model="item" :schema="item_form_schema">
                <template v-slot:left_side>
                    <div class="text-center mt-2">
                        <b-button variant="success" @click="toLessonPage">View List Lessons</b-button>
                    </div>
                </template>
            </form-generator>
            <b-row>
                <b-col cols="4"></b-col>
                <b-col cols="8">
                    <div class="d-flex justify-content-between mb-2">
                        <div class="d-flex align-items-center">
                            <span class="text-nowrap mr-1">Entry Level</span>
                            <b-input v-model="item.entry_level"></b-input>
                        </div>
                        <div class="d-flex align-items-center">
                            <span class="text-nowrap mr-1">Output Level</span>
                            <b-input v-model="item.output_level"></b-input>
                        </div>
                    </div>
                    <h3 class="mb-1">FAQ</h3>
                    <div>
                        <app-collapse v-if="item.faqs.length" type="shadow" class="my-2 border p-1">
                            <app-collapse-item v-for="(faq, index) in item.faqs" :key="faq._id" :title="`FAQ - Question ${index + 1}`">
                                <template slot="header">
                                    <div class="d-flex justify-content-between align-items-center flex-grow-1 w-100">
                                        <div class="d-flex align-items-center w-100 group-grab-button" style="cursor: grab;">
                                        <feather-icon icon="TargetIcon" />
                                        <span class="lead collapse-title ml-50 text-truncate d-block w-100" style="max-width: 90%">{{ `FAQ - Question ${index + 1}` }}</span>
                                        </div>
                                        <div style="position: absolute; right: 30px; ">
                                            <b-button variant="flat-danger" class="btn-icon rounded-circle"
                                                @click.stop="deleteFaq(index)"
                                            >
                                                <feather-icon icon="Trash2Icon" />
                                            </b-button>
                                        </div>
                                    </div>
                                </template>
                                <form-generator :model="faq" :schema="faq_form_schema" />
                            </app-collapse-item>
                        </app-collapse>
                        <div class="text-center">
                            <b-button variant="success" @click="addFaq">+ Add FAQ</b-button>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-card>
        </section>
        <action-buttons
            update-button
            back-button
            delete-button
            @update="update"
            :is-updating="isUpdating"
            @delete="deleteItem"
            :is-deleting="isDeleting"
        />
    </div>  
    
</template>
<script>
const item_form_schema = [
  {
    cols: 4,
    fields: [
        { label: 'Background', field: 'background', input_type: 'image', validate: { required: true } },
        { label: 'Web Background', field: 'web_background', input_type: 'image' },
    ],
    name: "left_side"
  },
  {
    cols: 8,
    fields: [
        { label: 'Title', field: 'title', validate: { required: true } },
        { label: 'Subtitle', field: 'subTitle', input_type:'ui-component', validate: { required: true } },
        { label: 'Key', field: 'key', validate: { required: true } },
        { label: 'Display in Learn Tab', field: 'learn_tab_display', input_type: 'switch' },
        { label: 'Course Description', field: 'description', input_type:'ui-component' },

    ]
  }
];
const faq_form_schema = [
    {
        cols: 12,
        fields: [
            { label: 'FAQ - Key', field: 'key'},
            { label: 'FAQ - Question', field: 'question', input_type:'ui-component'},
            { label: 'FAQ - Answer', field: 'answer', input_type:'ui-component'},
        ]
    }
]
import service from '../service'
import _ from 'lodash'
export default {
    data(){
        return{
            item_form_schema,
            item: null,
            isUpdating: false,
            isDeleting: false,
            faq_form_schema,
        }
    },
    computed: {
        itemId() {
            return this.$route.params.id;
        },
        breadcrumbItems() {
            let items = [
                {
                    text: 'Courses',
                    to: { name: 'courses-list' },
                },
                {
                    text: `${this.item ? this.item.title : this.itemId}`, active: true
                },
            ]
            return items
        },
        authLanguage() {
            return this.$store.getters["auth/language"];
        },
    },
    created(){
        this.getProduct()
    },
    methods:{
        async getProduct(){
            this.item = await service.get({id: this.itemId});
        },
        async update() {
            this.isUpdating = true;
            let data = {...this.item};
            data.name = JSON.stringify(data.name)
            await service.update({
                data: JSON.stringify(data),
            });
            this.getProduct();
            this.isUpdating = false;
        },
        async deleteItem() {
            await service.delete({ id: this.itemId });
            this.$router.push({ name: "iap-product-list" });
        },
        addFaq(){
            this.item.faqs.push({
                key: null,
                question: {
                    type: 'translatable-text',
                    meta: {
                    default_lang: 'vi'
                    },
                    value: '',
                    translations: [
                        {
                            language: 'vi',
                            value: ''
                        }
                    ]
                },
                answer: {
                    meta: {
                        default_lang: "en"
                    },
                    type: "translatable-richtext",
                    value: null,
                    translations: []
                }
            })
        },
        toLessonPage(){
            this.$router.push({name: "course-lesson", params: {courseId: this.itemId}})
        },
        deleteFaq(index){
            this.item.faqs.splice(index, 1);
        }
    }
}
</script>